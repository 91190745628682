import "lazysizes";
import {FloatLabels} from "@keodesign/float-labels";
import Splide from "@splidejs/splide";


var html_classes = document.documentElement.classList;
html_classes.remove("no-js");
html_classes.add("js");
window.onload = function() {    
	
	setupSlideMenu();
	const map_click = document.getElementById("map-click");
	if (map_click) {
		map_click.addEventListener("click",() => {
			// shield the map on mobile for easy scrolling
			map_click.style.display = "none";
		});
	}
	new FloatLabels(".js-float-wrap");

	const contact_click = document.getElementById("contact-form-btn");
	if (contact_click) {
		const contact_form = document.querySelector(".contact-form");
		contact_click.addEventListener("click",() => {
			contact_form.classList.toggle("active");
		});

	}

	let elms = document.getElementsByClassName("splide_big");
	for ( let i = 0, len = elms.length; i < len; i++ ) {
		new Splide(elms[i], {
			type   : "loop",
			drag   : true,
			snap   : true,
			perPage: 3,
			perMove: 1,
			pagination : false,
			autoplay: true,
			breakpoints: {
				760: {
					perPage: 2,
					perMove: 1,
				},
				576:{
					perPage: 1,
					perMove: 1,
				},
				400:{
					arrows:false,
				}
			}
		}).mount();
	}

	let elms2 = document.getElementsByClassName("splide_small");
	for ( let i = 0, len = elms2.length; i < len; i++ ) {
		console.log(elms2[i]);
		new Splide(elms2[i], {
			type   : "loop",
			drag   : true,
			snap   : true,
			arrows : false,
			pagination : true,
			autoplay: true,
		}).mount();
	}
};


export function setupSlideMenu(){
	const menu_buttons = [...document.querySelectorAll("#mobile-menu, .slide-menu h3")];
	menu_buttons.forEach(el => {
		el.addEventListener("click", ()=>{
			$("body").toggleClass("slide-menu-active");
		});
	});
	const menu_items = [...document.querySelectorAll(".slide-menu li")];
	menu_items.forEach((element) => {		
		if ([...element.querySelectorAll("ul")].length > 0) {
			element.insertAdjacentHTML("beforeend","<span class=\"expand\"><i class=\"nav-arrow\"></i></span>");
			
			// expander.click(function(e) {
			// 	e.stopPropagation();
			// 	$el.toggleClass("expand-active");
			// });
		}
	});
	const menu_expanders = [...document.querySelectorAll(".slide-menu .expand")];
	menu_expanders.forEach(el => {
		el.addEventListener("click", (e)=> {
			e.stopPropagation();
			el.closest("li").classList.toggle("expand-active");
		});
	});

}

//add style attribute with background image when lazyload event fired
// Setup background-image elements with class="lazyload" and data-bg-url-"https://domain.com/image/url/file.jpg".
// When they become visible, the lazy loader will fire this, and we will update the background image style.
// A low res fallback background image style could be set, so that something is visible before this triggers.
document.addEventListener("lazybeforeunveil", function(e){
	var container = e.target;
	var bgUrl = container.dataset.bgUrl;
	if (bgUrl) {
		container.style.backgroundImage = "url('" + bgUrl + "')";
		delete container.dataset.bgUrl;
	}
});